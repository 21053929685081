import '../scss/main.scss';
import { gsap } from 'gsap';
import AOS from 'aos';
import 'aos/dist/aos.css';
import SimpleLightbox from "simplelightbox/dist/simple-lightbox.esm";

AOS.init()
let header = document.querySelector('.header')
let footer = document.querySelector('footer')
window.addEventListener('scroll' ,() => {
    if (window.scrollY > 0) {
        if (!header.classList.contains('header__animate')) {
            header.classList.add('header__animate')
        }
        setFooterLength()
    } else {
        if (header.classList.contains('header__animate')) {
            header.classList.remove('header__animate')
        }
    }
    if (window.scrollY > window.window.innerHeight) {
        footer.style.zIndex = '1'
    } else {
        footer.style.zIndex = '0'
    }
})
let langBtn = document.querySelector('.header__box__line__cell__btn--lang')
langBtn.addEventListener('click', e => {
    e.preventDefault()
    if(!langBtn.parentNode.classList.contains('header__box__line__cell--open')) {
        langBtn.parentNode.classList.add('header__box__line__cell--open')
    }
})
document.querySelector('.header__box__line__cell-long__btn--stairs--intro').addEventListener('animationend', e => {
    document.querySelector('.header__box__line__cell-long__btn--stairs--intro').classList.remove('header__box__line__cell-long__btn--stairs--intro')
})
document.querySelector('.header__box__line__cell__btn--nav--intro').addEventListener('animationend', e => {
    document.querySelector('.header__box__line__cell__btn--nav--intro').classList.remove('header__box__line__cell__btn--nav--intro')
})
document.querySelector('.header__box__line__cell__btn--lang--intro').addEventListener('animationend', e => {
    document.querySelector('.header__box__line__cell__btn--lang--intro').classList.remove('header__box__line__cell__btn--lang--intro')
})
document.querySelector('.cover__bg-box__title--intro').addEventListener('animationend', e => {
    document.querySelector('.cover__bg-box__title--intro').classList.remove('cover__bg-box__title--intro')
})
document.querySelector('.header__box__line__cell-long__logo--intro').addEventListener('animationend', e => {
    document.querySelector('.header__box__line__cell-long__logo--intro').classList.remove('header__box__line__cell-long__logo--intro')
})
let navBtn = document.querySelector('.header__box__line__cell__btn')
let navigation = document.querySelector('.navigation')
let links = document.querySelectorAll('.navigation__container__list__item__link')
let items = document.querySelectorAll('.navigation__container__list__item')
let tl = null
navBtn.addEventListener('click', e => {
    e.preventDefault()
    if (!navigation.classList.contains('navigation__active')) {
        navigation.classList.add('navigation__active')
        //document.querySelector('body').style.position = "fixed"
        if (!header.classList.contains('header__nav')) {
            header.classList.add('header__nav')
        }
        let time = .7
        items.forEach(item => {
            item.style.overflow = 'hidden'
        })
        if (tl === null) {
            tl = gsap.timeline()
            links.forEach(link => {
                tl.fromTo(link, {transform: 'translateY(100%)'}, {transform: 'translateY(0)', ease: 'easeOut',
                    duration: .6, onComplete: addOverflow, onCompleteParams: [link]}, time)
                time += .2
            })
        } else {
            tl.restart().timeScale(1).play()
        }
        langBtn.parentNode.classList.remove('header__box__line__cell--open')
    } else {
        navigation.classList.remove('navigation__active')
        header.classList.remove('header__nav')
        //document.querySelector('body').style.position = "initial"
        langBtn.parentNode.classList.remove('header__box__line__cell--open')
        tl.timeScale(.1).reverse()
    }
})
function setFooterLength() {
    const header = document.querySelector('.header__white')
    if (window.innerHeight - header.clientHeight < footer.clientHeight) {
        document.querySelector('main').style.marginBottom = 0
        footer.style.position = 'relative'
    } else {
        document.querySelector('main').style.marginBottom = footer.clientHeight + 'px'
        footer.style.position = 'fixed'
    }
}
function addOverflow(link) {
    link.parentNode.style.overflow = 'visible'
}
window.addEventListener('resize', () => {
    setFooterLength()
})
setFooterLength()
document.querySelector('.footer__move-up__btn').addEventListener('click', e => {
    e.preventDefault()
    document.querySelector('html').scrollIntoView({behavior: "smooth", block: "start", inline: "start"})
})

let portfolioCounter = 0
let progressBarScale = 0.2
let progressBarScaleChange = 0.2
let moved = 0
const portfolioList = document.querySelector('.portfolio__box__cards__list')
const progressBar = document.querySelector('.portfolio__line__progress__scale')
const portfolioNavBtn = document.querySelector('.portfolio__box__cards__nav__part__btn--2')
const portfolioNavBtnPrevious = document.querySelector('.portfolio__box__cards__nav__part__btn--1')
if (portfolioNavBtn) {
    window.addEventListener('resize', e => {
        if (documentWidth != window.innerWidth) {
            documentWidth = window.innerWidth
            moved = 0
            portfolioCounter = 0
            if (!portfolioNavBtnPrevious.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
                portfolioNavBtnPrevious.classList.add('portfolio__box__cards__nav__part__btn--opacity')
            }
            if (portfolioNavBtn.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
                portfolioNavBtn.classList.remove('portfolio__box__cards__nav__part__btn--opacity')
            }
            setPortfolioMaxCount()
            portfolioList.style.transform = "translateX(0px)"
            progressBar.style.transform = "translate3d(0px, 0px, 0px) scaleX("+progressBarScale+") scaleY(1)"
        }
    })
    portfolioNavBtn.addEventListener('click', e => {
        e.preventDefault()
        portfolioMoveForward()
    })
}
let portfolioMaxMoveCount = 4
let documentWidth = window.innerWidth
setPortfolioMaxCount()
function setPortfolioMaxCount() {
    if (documentWidth > 1325) {
        portfolioMaxMoveCount = 4
        progressBarScale = .2
        progressBarScaleChange = .2
    }
    if (documentWidth <= 1325) {
        portfolioMaxMoveCount = 5
        progressBarScale = .167
        progressBarScaleChange = .167
    }
    if (documentWidth < 972) {
        portfolioMaxMoveCount = 6
        progressBarScale = .1428
        progressBarScaleChange = .1428
    }
    if (documentWidth < 600) {
        portfolioMaxMoveCount = 7
        progressBarScale = .125
        progressBarScaleChange = .125
    }
}
function portfolioMoveForward() {
    if (portfolioCounter < portfolioMaxMoveCount) {
        if (portfolioNavBtnPrevious.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
            portfolioNavBtnPrevious.classList.remove('portfolio__box__cards__nav__part__btn--opacity')
        }
        portfolioCounter++
        if (portfolioCounter === portfolioMaxMoveCount) {
            if (!portfolioNavBtn.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
                portfolioNavBtn.classList.add('portfolio__box__cards__nav__part__btn--opacity')
            }
        }
        moved -= 320
        progressBarScale += progressBarScaleChange
        portfolioList.style.transform = "translateX("+moved+'px)'
        progressBar.style.transform = "translate3d(0px, 0px, 0px) scaleX("+progressBarScale+") scaleY(1)"
    }
}

function portfolioMoveBackwards() {
    if (portfolioCounter > 0) {
        if (portfolioNavBtn.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
            portfolioNavBtn.classList.remove('portfolio__box__cards__nav__part__btn--opacity')
        }
        portfolioCounter--
        if (portfolioCounter === 0) {
            if (!portfolioNavBtnPrevious.classList.contains('portfolio__box__cards__nav__part__btn--opacity')) {
                portfolioNavBtnPrevious.classList.add('portfolio__box__cards__nav__part__btn--opacity')
            }
        }
        moved += 320
        progressBarScale -= progressBarScaleChange
        portfolioList.style.transform = "translateX("+moved+'px)'
        progressBar.style.transform = "translate3d(0px, 0px, 0px) scaleX("+progressBarScale+") scaleY(1)"
    }
}
if (portfolioNavBtnPrevious) {
    portfolioNavBtnPrevious.addEventListener('click', e => {
        e.preventDefault()
        portfolioMoveBackwards()
    })
}
const calculatorLeftBtn = document.querySelector('.calculator__left__btn')
const calculatorRightBtn = document.querySelector('.calculator__right__btn')
const calculatorLRBody = document.querySelector('.calculator__lr__body')
if (calculatorLeftBtn) {
    calculatorLeftBtn.addEventListener('click', e => {
        e.preventDefault()
        if (calculatorLRBody.classList.contains('calculator__right')) {
            calculatorLRBody.classList.remove('calculator__right')
        }
        if (!calculatorLRBody.classList.contains('calculator__left')) {
            calculatorLRBody.classList.add('calculator__left')
        }
    })
    calculatorRightBtn.addEventListener('click', e => {
        e.preventDefault()
        if (calculatorLRBody.classList.contains('calculator__left')) {
            calculatorLRBody.classList.remove('calculator__left')
        }
        if (!calculatorLRBody.classList.contains('calculator__right')) {
            calculatorLRBody.classList.add('calculator__right')
        }
    })
}
const calculator = document.querySelector('.calculator')
if (calculator) {
    document.querySelectorAll('.drewno').forEach(ele => {
        ele.addEventListener('click', e => {
            cancelOthers(e.target)
            if (!e.target.classList.contains('calculator__chosen__text')) {
                e.target.classList.add('calculator__chosen__text')
            }
            setHiddens(e.target)
        })
    })
    document.querySelectorAll('.powierzchnia').forEach(ele => {
        ele.addEventListener('click', e => {
            cancelOthers(e.target)
            if (!e.target.classList.contains('calculator__chosen__text')) {
                e.target.classList.add('calculator__chosen__text')
            }
            setHiddens(e.target)
        })
    })
    const svgs = document.querySelectorAll('svg')
    svgs.forEach(svg => {
        svg.addEventListener('click', e => {
            cancelOthers(e.target)
            if (!e.target.classList.contains('calculator__chosen__svg')) {
                e.target.classList.add('calculator__chosen__svg')
            }
            setHiddens(e.target)
        })
    })

    let kierunek = document.querySelector('#kierunek')
    kierunek.value = 1
    let konstrukcja = document.querySelector('#konstrukcja')
    konstrukcja.value = 1
    let podstopnie = document.querySelector('#podstopnie')
    podstopnie.value = 1
    let stopienblokowy = document.querySelector('#stopienblokowy')
    stopienblokowy.value = 1
    let balustrada = document.querySelector('#balustrada')
    balustrada.value = 1
    let drewno = document.querySelector('#drewno')
    drewno.value = 1
    let powierzchnia = document.querySelector('#powierzchnia')
    powierzchnia.value = 1
    let dziura = document.querySelector('#dziura')
    dziura.value = 1
    document.querySelector('.calculator__box__form-box__form__submit').addEventListener('click', e => {
        e.preventDefault()
        if (validateForm()) {
            document.querySelector('form').submit()
        }
    })
    function cancelOthers(element) {
        if (element.classList.contains('dziura')) {
            document.querySelectorAll('.dziura').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('kierunek')) {
            document.querySelectorAll('.kierunek').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('konstrukcja')) {
            document.querySelectorAll('.konstrukcja').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('podstopnie')) {
            document.querySelectorAll('.podstopnie').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('stopienblokowy')) {
            document.querySelectorAll('.stopienblokowy').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('balustrada')) {
            document.querySelectorAll('.balustrada').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__svg')) {
                        ele.classList.remove('calculator__chosen__svg')
                    }
                }
            })
        }
        if (element.classList.contains('drewno')) {
            document.querySelectorAll('.drewno').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__text')) {
                        ele.classList.remove('calculator__chosen__text')
                    }
                }
            })
        }
        if (element.classList.contains('powierzchnia')) {
            document.querySelectorAll('.powierzchnia').forEach(ele => {
                if (ele !== element) {
                    if (ele.classList.contains('calculator__chosen__text')) {
                        ele.classList.remove('calculator__chosen__text')
                    }
                }
            })
        }
    }
    function setHiddens(element)
    {
        if (element.classList.contains('balustrada')) {
            balustrada.value = element.dataset.nr
        }
        if (element.classList.contains('stopienblokowy')) {
            stopienblokowy.value = element.dataset.nr
        }
        if (element.classList.contains('podstopnie')) {
            podstopnie.value = element.dataset.nr
        }
        if (element.classList.contains('konstrukcja')) {
            konstrukcja.value = element.dataset.nr
        }
        if (element.classList.contains('kierunek')) {
            kierunek.value = element.dataset.nr
        }
        if (element.classList.contains('dziura')) {
            dziura.value = element.dataset.nr
        }
        if (element.classList.contains('drewno')) {
            drewno.value = element.dataset.nr
        }
        if (element.classList.contains('powierzchnia')) {
            powierzchnia.value = element.dataset.nr
        }
    }
    const names = ['measuresa', 'measuresb', 'measuresc', 'measuresd', 'email', 'phone', 'zipcode', 'street', 'city', 'name']
    names.forEach(id => {
        const input = document.querySelector('#'+id)
        input.addEventListener('change', e => {
            if (e.target.value !== '') {
                if (e.target.parentNode.classList.contains('calculator__box__form-box__form__section__body__element--error')) {
                    e.target.parentNode.classList.remove('calculator__box__form-box__form__section__body__element--error')
                }
            }
        })
        input.addEventListener('keydown', e => {
            if (e.target.value !== '') {
                if (e.target.value.length > 30) {
                    const key = e.key;
                    if (!(key === "Backspace" || key === "Delete" || key === "ArrowRight" || key === "ArrowLeft")) {
                        e.preventDefault()
                    }
                }
            }
        })
    })

    function validateForm()
    {
        let status = true
            names.forEach(id => {
            const input = document.querySelector('#'+id)
            if (input.value === '') {
                status = false
                if (!input.parentNode.classList.contains('calculator__box__form-box__form__section__body__element--error')) {
                    input.parentNode.classList.add('calculator__box__form-box__form__section__body__element--error')
                }
            }
        })
        let emailInput = document.querySelector('#email')
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(emailInput.value) === false)
        {
            if (!emailInput.parentNode.classList.contains('calculator__box__form-box__form__section__body__element--error')) {
                emailInput.parentNode.classList.add('calculator__box__form-box__form__section__body__element--error')
            }
            status = false
        }
        if (status === false) {
            const errorText = document.querySelector('.calculator__box__form-box__form__errors')
            if (errorText.classList.contains('hide')) {
                errorText.classList.remove('hide')
            }
            const submitBtn = document.querySelector('.calculator__box__form-box__form__submit')
            if (!submitBtn.classList.contains('calculator__box__form-box__form__submit--error')) {
                submitBtn.classList.add('calculator__box__form-box__form__submit--error')
                setTimeout(() => {
                    submitBtn.classList.remove('calculator__box__form-box__form__submit--error')
                }, 400);
            }
        }
        return status
    }
}
const gallery = document.querySelector('.gallery__box__content')
if (gallery) {
    var lightbox = new SimpleLightbox('.gallery__box__content a', { /* options */ });
}
const portfolioBoxMain = document.querySelector('.portfolio__box')
if (portfolioBoxMain) {
    let touchstartX = 0;
    let touchendX = 0;

    let clickstartX = 0;
    let clickendX = 0;
    function zeroVariables() {
        touchstartX = 0;
        touchendX = 0;

        clickstartX = 0;
        clickendX = 0;
    }
    function handleGesture() {
        if (touchendX < touchstartX - 20 || clickendX < clickstartX - 20) {
            portfolioMoveForward()
        }
        if (touchendX - 20 > touchstartX || clickendX - 20 > clickstartX) {
            portfolioMoveBackwards()
        }
        zeroVariables()
    }
    const gesuredZone = portfolioBoxMain

    gesuredZone.addEventListener('touchstart', event => {
        touchstartX = event.changedTouches[0].screenX;
    }, false);
    gesuredZone.addEventListener('touchend', event => {
        touchendX = event.changedTouches[0].screenX;
        handleGesture();
    }, false);
    gesuredZone.addEventListener('mousedown', event => {
        clickstartX = event.clientX;
    }, false);
    gesuredZone.addEventListener('mouseup', event => {
        clickendX = event.clientX;
        handleGesture();
    }, false);
}
